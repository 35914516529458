.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 50%;
  box-shadow: 1px 1px 512px 1px rgb(236, 240, 241);
}

.anima {
  height: 300px;
  display: flex;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0f3a63;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(224, 174, 11);
}

h2, h1 {
  text-shadow: 1px 1px 4px rgb(150,50,50);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    height: 300px; 
  }
  25% {
    transform: rotate(90deg);
    height: 225px;
  }
  50% {
    transform: rotate(180deg);
    height: 150px;
  }
  75% {
    transform: rotate(270deg);
    height: 225px;
  }
  to {
    transform: rotate(360deg);
    height: 300px;
  }
}
